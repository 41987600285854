import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import { useAtom } from "jotai"
import { FC } from "react"
import { userAtom } from "src/shared/stores"

const OverviewPage: FC = () => {
  const [{ data: user }] = useAtom(userAtom)

  return (
    <Grid
      data-testid="overviewPage"
      className="McpGrid"
      container
      item
      spacing={2}
      xs={12}
    >
      <Grid item xs={12} md={8}>
        <Box className="McpBox">
          <Typography variant="h6" gutterBottom>
            All User Attributes
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer data-testid="userTable">
                <Table stickyHeader size="small" aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Key</TableCell>
                      <TableCell align="right">Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(user ?? []).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          {key}
                        </TableCell>
                        <TableCell align="right">
                          {typeof value === "boolean" && value == true ? (
                            <CheckCircleIcon />
                          ) : (
                            value
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid container item spacing={2} xs={12} md={4}>
        <Grid item xs={12}>
          <Box className="McpBox">
            <Typography variant="h6" gutterBottom>
              Hier könnte Ihre Werbung stehen
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="McpBox">
            <Typography variant="h6" gutterBottom>
              DUMMY
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Grid container item spacing={2} xs={12}>
        <Grid item xs={4}>
          <Box className="McpBox">
            <Typography variant="h6" gutterBottom>
              Test 1
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="McpBox">
            <Typography variant="h6" gutterBottom>
              Test 1
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="McpBox">
            <Typography variant="h6" gutterBottom>
              Test 1
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OverviewPage
