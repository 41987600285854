import { Check, Close, Edit } from "@mui/icons-material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import {
  AlertTitle,
  Box,
  Container,
  Grid,
  IconButton,
  Tab,
  TextField,
  Typography
} from "@mui/material"
import { useAtom, useSetAtom } from "jotai"
import { useSnackbar } from "notistack"
import type { FC, SyntheticEvent } from "react"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import {
  ProjectChangeStatusBox,
  ProjectCommentListView,
  ProjectSummaryView
} from "src/features/project"
import { ApiError } from "src/shared/client"
import { Alert, LoadingContainer } from "src/shared/components"
import {
  projectDataAtom,
  projectDetailsTabAtom,
  projectIdAtom,
  projectStatusChangeListAtom,
  updateProjectDataAtom
} from "src/shared/stores"

type ProjectDetailsProps = {
  handleDrawerClose: () => void
}

const ProjectDetails: FC<ProjectDetailsProps> = ({ handleDrawerClose }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "projectDetails"
  })

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const setProjectId = useSetAtom(projectIdAtom)
  const [tabPage, setTabPage] = useAtom(projectDetailsTabAtom)
  const handleTabChange = (_event: SyntheticEvent, newTabPage: string) => {
    setTabPage(newTabPage)
  }
  const [{ mutate: updateProjectData, isPending: isUpdatingProjectData }] =
    useAtom(updateProjectDataAtom)
  const [
    {
      data: projectData,
      error: projectDataFetchingError,
      isFetching: isFetchingProjectData,
      isPending,
      isError: isErrorFetchingProjectData,
      refetch: refetchProjectData
    }
  ] = useAtom(projectDataAtom)

  const [
    {
      data: projectStatusAndCommentData,
      isError: isErrorFetchingProjectStatusAndCommentData
    }
  ] = useAtom(projectStatusChangeListAtom)

  const projectStatus = projectData?.status as ProjectStatus

  const [newProjectName, setNewProjectName] = useState<string | null>(null)
  const [isEditingProjectName, setIsEditingProjectName] = useState(false)

  const handleProjectNameUpdate = (
    projectData: ProjectRecord,
    newProjectName: string | null
  ) => {
    if (newProjectName !== null && newProjectName !== "") {
      updateProjectData(
        { ...projectData, name: newProjectName },
        {
          onSuccess: async () => {
            await refetchProjectData()
            setIsEditingProjectName(false)
            enqueueSnackbar({
              variant: "success",
              message: t("summaryTab.projectDataUpdateSuccessSnackbar")
            })
          },
          onError: async (error) => {
            const response: ServiceError = await error.errorResponse.json()

            enqueueSnackbar({
              variant: "detailedSnackbar",
              message: t(response.code, { keyPrefix: "errorCodes" }),
              details: response.message,
              autoHideDuration: null
            })

            setIsEditingProjectName(false)
          }
        }
      )
    } else {
      setIsEditingProjectName(false)
    }
  }

  const handleCloseButtonClick = useCallback(() => {
    handleDrawerClose()
    setProjectId(undefined)
    navigate("/projects")
  }, [handleDrawerClose, navigate, setProjectId])

  useEffect(() => {
    if (projectDataFetchingError) {
      const enqueueErrorSnackbar = async (error: ApiError) => {
        const response: ServiceError = await error.errorResponse.json()

        enqueueSnackbar({
          variant: "detailedSnackbar",
          message: t(response.code, { keyPrefix: "errorCodes" }),
          details: response.message,
          autoHideDuration: null
        })
      }

      enqueueErrorSnackbar(projectDataFetchingError)
    }
  }, [enqueueSnackbar, projectDataFetchingError, t])

  if (isPending) {
    return <LoadingContainer />
  }

  if (isErrorFetchingProjectData || !projectData) {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        data-testid="projectDetailsError"
        height="100px"
      >
        {t("projectDataFetchFailureAlert")}
      </Box>
    )
  }

  return (
    <Container data-testid="projectDetails" maxWidth={false}>
      <Box className="McpDrawerBox">
        <Grid container className="McpDrawerContent">
          <Grid
            xs={12}
            item
            container
            className="McpDrawerFixed"
            sx={{ backgroundColor: "#fff" }}
          >
            <Grid
              xs={12}
              item
              container
              alignItems="center"
              justifyContent="space-between"
              className="McpDrawerFixedSubheadline"
            >
              <Grid xs="auto" item>
                <Box display="flex" alignItems="center">
                  {isEditingProjectName ? (
                    <>
                      <TextField
                        disabled={
                          isUpdatingProjectData || isFetchingProjectData
                        }
                        defaultValue={projectData.name}
                        variant="outlined"
                        size="small"
                        sx={{ marginRight: 1 }}
                        onChange={(e) => setNewProjectName(e.target.value)}
                      />
                      <IconButton
                        disabled={
                          isUpdatingProjectData || isFetchingProjectData
                        }
                        color="secondary"
                        onClick={() =>
                          handleProjectNameUpdate(projectData, newProjectName)
                        }
                      >
                        <Check />
                      </IconButton>
                      <IconButton
                        disabled={
                          isUpdatingProjectData || isFetchingProjectData
                        }
                        onClick={() => {
                          setIsEditingProjectName(false)
                          if (newProjectName) setNewProjectName(null)
                        }}
                        color="error"
                      >
                        <Close />
                      </IconButton>
                    </>
                  ) : (
                    <Typography variant="h5">
                      {projectData.name}
                      <IconButton onClick={() => setIsEditingProjectName(true)}>
                        <Edit fontSize="small" />
                      </IconButton>
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid xs="auto" item>
                <IconButton
                  onClick={handleCloseButtonClick}
                  data-testid="projectDrawerCloseButton"
                >
                  <Close fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>

            <Grid xs={12} item sx={{ mt: 2 }}>
              <Alert status={projectData.status}>
                <AlertTitle>{t(`headlineAlert.${projectStatus}`)}</AlertTitle>
              </Alert>
            </Grid>
          </Grid>

          <Grid xs={12} item className="McpTabs">
            <TabContext value={tabPage}>
              <Box className="McpTabsContext">
                <TabList
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons={false}
                >
                  <Tab label={t("summaryTab.tabLabel")} value="1" />
                  <Tab label={t("commentsTab.tabLabel")} value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                {<ProjectSummaryView projectData={projectData} />}
              </TabPanel>
              <TabPanel value="2">
                {isErrorFetchingProjectStatusAndCommentData ||
                !projectStatusAndCommentData ? (
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    data-testid="projectDetailsError"
                    height="100px"
                  >
                    {t("projectDataFetchFailureAlert")}
                  </Box>
                ) : (
                  <>
                    <ProjectCommentListView
                      projectData={projectData}
                      projectStatusAndCommentData={projectStatusAndCommentData}
                    />

                    {projectData.status === "WAITING_FOR_MERCHANT" && (
                      <ProjectChangeStatusBox />
                    )}
                  </>
                )}
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default ProjectDetails
