import { Shortcut } from "@mui/icons-material"
import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import { useAtom } from "jotai"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { normalizeWhitespace } from "src/shared/functions"
import {
  postProjectStatusChangeAtom,
  projectStatusChangeListAtom
} from "src/shared/stores"

import ProjectStatusChangeConfirmationDialog from "./ProjectStatusChangeConfirmationDialog"

const ProjectChangeStatusBox = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "projectDetails"
  })

  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const [{ mutate: postProjectStatusChange }] = useAtom(
    postProjectStatusChangeAtom
  )
  const [{ refetch: refetchComments }] = useAtom(projectStatusChangeListAtom)

  const [comment, setComment] = useState<string | null>(null)

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const handleStatusChange = async (status: ProjectStatus) => {
    postProjectStatusChange(
      {
        status,
        comment: comment ? normalizeWhitespace(comment) : comment
      },
      {
        onSuccess: async () => {
          await refetchComments()
          setComment(null)
          await queryClient.invalidateQueries({
            queryKey: ["projectListData"],
            refetchType: "active"
          })
          await queryClient.invalidateQueries({
            queryKey: ["projectData"],
            refetchType: "active"
          })
          setIsDialogOpen(!isDialogOpen)
        },
        onError: async (error) => {
          const response: ServiceError = await error.errorResponse.json()
          enqueueSnackbar({
            variant: "detailedSnackbar",
            message: t(`${response.code}`, { keyPrefix: "errorCodes" }),
            details: response.message,
            autoHideDuration: null
          })
          setIsDialogOpen(!isDialogOpen)
        }
      }
    )
  }

  return (
    <>
      <Grid xs={12} item className="McpCommentBox">
        <Box className="McpBox" data-testid="commentInputButtonGroup">
          <Box flexDirection={"row"}>
            <Grid xs={12} container item justifyContent={"space-between"}>
              <Grid item xs={"auto"}>
                <Typography variant="h6">
                  {t("commentsTab.commentInputTitle")}
                </Typography>
              </Grid>
              <Grid item xs={"auto"} marginRight={1} marginTop={1}>
                {(comment === null ||
                  (comment && normalizeWhitespace(comment).length < 10)) && (
                  <Typography>
                    {t("commentsTab.charsLeftText", {
                      charsLeft:
                        10 - (comment ? normalizeWhitespace(comment).length : 0)
                    })}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
          <Grid xs={12} container item>
            <TextField
              fullWidth
              name="comment"
              label={t("commentsTab.comment")}
              type="text"
              data-testid="projectCommentField"
              id="comment"
              value={comment || ""}
              onChange={(e) => setComment(e.target.value)}
            />

            <Button
              className="McpButton"
              fullWidth
              startIcon={<Shortcut />}
              color="warning"
              data-testid="proj-return-button"
              variant="contained"
              disabled={
                isDialogOpen ||
                (comment !== null &&
                  normalizeWhitespace(comment).length < 10) ||
                !comment
              }
              onClick={() => {
                setIsDialogOpen(!isDialogOpen)
              }}
            >
              {t("resubmitBtn", { keyPrefix: "common" })}
            </Button>
          </Grid>
        </Box>
      </Grid>

      <ProjectStatusChangeConfirmationDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        newProjectStatus="READY_FOR_APPROVAL"
        comment={comment ? normalizeWhitespace(comment) : comment}
        handleStatusChange={handleStatusChange}
      />
    </>
  )
}

export default ProjectChangeStatusBox
