import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Grid, Tab } from "@mui/material"
import { SyntheticEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { PasswordChangeView } from "src/features/account"

const AccountDataBox = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "accountPage.account-data"
  })
  const [tabPage, setTabPage] = useState("1")
  const handleTabChange = (_event: SyntheticEvent, newTabPage: string) => {
    setTabPage(newTabPage)
  }

  return (
    <Grid xs={12} item className="McpTabs" margin={2} alignItems="flex-start">
      <TabContext value={tabPage}>
        <Box className="McpTabsContext">
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            className="borderBottom"
          >
            <Tab label={t("profileTab.title")} value="1" />
            <Tab label={t("changePasswordTab.title")} value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">profile</TabPanel>
        <TabPanel value="2">
          <PasswordChangeView />
        </TabPanel>
      </TabContext>
    </Grid>
  )
}

export default AccountDataBox
