import { Chip as MUIChip } from "@mui/material"
import useChipConfig from "src/shared/components/api/useChipConfig"

type ChipLabelType = "onboardings" | "merchants" | "projects"

const Chip = ({
  status,
  pathname
}: {
  status?: ProjectStatus | ProjectActivityStatus
  pathname?: ChipLabelType
}) => {
  const { chipColor, CHIP_DEFAULT_TEXT, chipLabel } = useChipConfig()

  return !status || !pathname ? (
    <MUIChip color="primary" label={CHIP_DEFAULT_TEXT} size="small" />
  ) : (
    <MUIChip
      color={chipColor[status]}
      label={chipLabel[status][pathname]}
      size="small"
    />
  )
}

export default Chip
