import { atom } from "jotai"
import { atomWithQuery } from "jotai-tanstack-query"
import type { AuthUser } from "src/shared/authentication"
import { authenticationService } from "src/shared/authentication"

export const authenticationStatusAtom = atom<string | null>(null)

export const userAtom = atomWithQuery<AuthUser>(() => ({
  queryKey: ["userData"],
  queryFn: async () => {
    const {
      email,
      given_name,
      family_name,
      sub,
      "custom:merchantId": merchantId
    } = await authenticationService.getUserAttributes()

    const authUserData: AuthUser = {
      email,
      given_name,
      family_name,
      sub,
      merchantId
    }

    return authUserData
  },
  retry: false,
  staleTime: Infinity,
  refetchOnWindowFocus: false
}))
