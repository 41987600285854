import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material"
import { useAtom } from "jotai"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import {
  postProjectStatusChangeAtom,
  projectDataAtom,
  projectListDataAtom,
  projectStatusChangeListAtom,
  updateProjectDataAtom
} from "src/shared/stores"

type ProjectStatusChangeConfirmationDialogProps = {
  comment: string | null
  handleStatusChange: (status: ProjectStatus) => void
  isDialogOpen: boolean
  setIsDialogOpen: (newState: boolean) => void
  newProjectStatus: "READY_FOR_APPROVAL"
}

const ProjectStatusChangeConfirmationDialog: FC<
  ProjectStatusChangeConfirmationDialogProps
> = ({
  isDialogOpen,
  setIsDialogOpen,
  newProjectStatus,
  comment,
  handleStatusChange
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "projectDetails.statusConfirmationDialog"
  })

  const [{ isFetching: isFetchingProjectData }] = useAtom(projectDataAtom)
  const [{ isPending: isUpdatingProjectData }] = useAtom(updateProjectDataAtom)
  const [{ isFetching: isFetchingProjectStatusData }] = useAtom(
    projectStatusChangeListAtom
  )
  const [{ isPending: isPostingProjectStatusChange }] = useAtom(
    postProjectStatusChangeAtom
  )
  const [{ isFetching: isFetchingProjectListData }] =
    useAtom(projectListDataAtom)

  return (
    <Dialog open={isDialogOpen} disableScrollLock>
      <DialogTitle id="alert-dialog-title">{t("dialogTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
          {t("dialogDescriptionText")}
        </DialogContentText>
        <DialogContentText data-testid="alert-dialog-comment-description">
          {comment !== null && comment !== ""
            ? t("availableCommentText", { comment })
            : t("noAvailableCommentText")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box className="McpButtons">
          <Button
            color="error"
            data-testid="dialog-cancel-button"
            disabled={
              isFetchingProjectData ||
              isUpdatingProjectData ||
              isFetchingProjectStatusData ||
              isFetchingProjectListData ||
              isPostingProjectStatusChange
            }
            onClick={() => setIsDialogOpen(!isDialogOpen)}
            autoFocus
          >
            {t("cancelBtn", { keyPrefix: "common" })}
          </Button>
          <LoadingButton
            variant="contained"
            color="success"
            data-testid="dialog-submit-button"
            loading={
              isFetchingProjectData ||
              isUpdatingProjectData ||
              isFetchingProjectStatusData ||
              isFetchingProjectListData ||
              isPostingProjectStatusChange
            }
            onClick={() => {
              handleStatusChange(newProjectStatus)
            }}
          >
            <span>{t("resubmitBtn", { keyPrefix: "common" })}</span>
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ProjectStatusChangeConfirmationDialog
