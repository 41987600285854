import { KeyboardArrowRight } from "@mui/icons-material"
import { Button, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import { useAtom, useSetAtom } from "jotai"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { CreateProjectDialog } from "src/features/project"
import {
  merchantDataAtom,
  projectDetailsTabAtom,
  projectIdAtom
} from "src/shared/stores"
import { ProjectDetails, ProjectList } from "src/widgets/projects"

const ProjectsPage = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "projectPage"
  })

  const { projectId } = useParams()
  const setProjectId = useSetAtom(projectIdAtom)
  const setProjectDetailsTab = useSetAtom(projectDetailsTabAtom)
  const [{ data: merchantData }] = useAtom(merchantDataAtom)

  const [open, setOpen] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const handleDrawerOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleDrawerClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleDialogOpen = useCallback(() => {
    setIsDialogOpen(true)
  }, [])

  useEffect(() => {
    if (projectId) {
      setProjectId(projectId)
      setOpen(true)
    }
  }, [projectId, setProjectId])

  // Cleanup function to reset merchantId and merchantDetailsTab when the component unmounts
  useEffect(
    () => () => {
      setProjectId(undefined)
      setProjectDetailsTab("1")
    },
    [setProjectId, setProjectDetailsTab]
  )

  return (
    <Grid container spacing={3}>
      <Grid container xs={open ? 6 : 12} sx={{ alignItems: "center" }}>
        <Grid xs={12} md={open ? 12 : 9} lg={open ? 7 : 9} xl={open ? 9 : 10.5}>
          <Grid>
            <Typography variant="h4">{t("pageHeadline")}</Typography>
          </Grid>
          <Grid>
            <Typography variant="caption">{t("pageSubheadline")}</Typography>
          </Grid>
        </Grid>
        {merchantData?.status === "ACTIVE" && (
          <Grid
            xs={open ? 12 : 6}
            md={open ? 12 : 3}
            lg={open ? 5 : 3}
            xl={open ? 3 : 1.5}
          >
            <Button
              data-testid="newProjectButton"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleDialogOpen}
            >
              {t("newProjectButton")} <KeyboardArrowRight />
            </Button>
          </Grid>
        )}
      </Grid>

      {/* DIVIDER FOR THE LINE */}
      {open && <Grid xs={6} />}

      <Grid xs={open ? 6 : 12} data-testid="projectTable">
        <ProjectList
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
      </Grid>
      {open && (
        <Grid className="McpDrawer" xs={open ? 6 : false}>
          <ProjectDetails handleDrawerClose={handleDrawerClose} />
        </Grid>
      )}

      <CreateProjectDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        handleDrawerOpen={handleDrawerOpen}
      />
    </Grid>
  )
}

export default ProjectsPage
