import { CopyAll, ErrorOutline } from "@mui/icons-material"
import {
  AlertTitle,
  Box,
  Chip as MUIChip,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Switch,
  Tooltip,
  Typography
} from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import { useAtom } from "jotai"
import { useSnackbar } from "notistack"
import { FC, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { ProjectActivityConfirmationDialog } from "src/features/project"
import { Alert, Chip } from "src/shared/components"
import { handleDate, handleTime } from "src/shared/functions"
import { updateProjectDataAtom } from "src/shared/stores"

const ProjectSummaryView: FC<{
  projectData: ProjectRecord
}> = ({ projectData }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "projectDetails"
  })
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const [{ mutate: updateProjectData }] = useAtom(updateProjectDataAtom)

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    if (projectData) {
      setIsChecked(projectData.activityStatus === "ACTIVE")
    }
  }, [projectData])

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const handleCopy = () => {
    if (projectData?.projectId) {
      navigator.clipboard.writeText(projectData.projectId!)
      setTooltipOpen(true)
      setTimeout(() => {
        setTooltipOpen(false)
      }, 2000)
    }
  }

  const handleProjectActivity = (newActivityStatus: ProjectActivityStatus) => {
    if (projectData) {
      updateProjectData(
        {
          ...projectData,
          activityStatus: newActivityStatus
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: ["projectListData"],
              refetchType: "active"
            })
            setIsDialogOpen(!isDialogOpen)
          },
          onError: async (error) => {
            const response: ServiceError = await error.errorResponse.json()

            enqueueSnackbar({
              variant: "detailedSnackbar",
              message: t(response.code, { keyPrefix: "errorCodes" }),
              details: response.message,
              autoHideDuration: null
            })
            setIsChecked(!isChecked)
          }
        }
      )
    } else return
  }

  const paymentMethods = [
    "Kreditkarte",
    "SEPA-Lastschrift",
    "PayPal",
    "Online Überweisung",
    "Vorkasse",
    "Paysafecard"
  ]

  return (
    <Grid container data-testid="projectSummaryView">
      {/* PROJECT DETAILS */}
      <Grid item xs={12}>
        <Box className="McpBox">
          <Typography variant="h6" gutterBottom>
            {t("summaryTab.projectDetailsHeadline")}
          </Typography>

          <Container>
            <Grid xs={12} container item alignItems={"flex-start"}>
              <Grid md={12} container item rowSpacing={1}>
                <Grid container item xs={12}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="caption">
                      {t("summaryTab.status")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Chip
                      status={projectData.status}
                      pathname="projects"
                    ></Chip>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="caption">
                      {t("summaryTab.createdAt")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography>{`${handleDate(projectData.createdAt)} ${t("at", { keyPrefix: "common" })} ${handleTime(projectData.createdAt)}`}</Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="caption">
                      {t("summaryTab.name")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography>{projectData.name}</Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="caption">
                      {t("summaryTab.url")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography>{projectData.url}</Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="caption">
                      {t("summaryTab.ageRating")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography>
                      {projectData.ageRating
                        ? t(
                            `createProjectDialog.possibleAgeRating.${projectData.ageRating}`
                          )
                        : "--"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="caption">
                      {t("summaryTab.category")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography>
                      {projectData.category
                        ? t(
                            `createProjectDialog.possibleCategories.${projectData.category}`
                          )
                        : "--"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={0.8}>
                    <ErrorOutline fontSize="small" />
                  </Grid>
                  <Grid item xs={11.2}>
                    <Typography variant="caption">
                      {t("summaryTab.projectEditInfoText")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Grid>

      {/* PROJECT API */}
      <Grid item xs={12}>
        <Box className="McpBox">
          <Grid item xs={12} justifyContent="space-between" position="relative">
            <Typography variant="h6">
              {t("summaryTab.projectApiHeadline")}
            </Typography>

            <Box position="absolute" top={0} right="40px">
              <FormControlLabel
                control={
                  <Switch
                    checked={isChecked}
                    onChange={(e) => {
                      setIsChecked(e.target.checked)
                      setIsDialogOpen(!isDialogOpen)
                    }}
                  />
                }
                label={
                  isChecked
                    ? t("summaryTab.activeLabel")
                    : t("summaryTab.inactiveLabel")
                }
              />
            </Box>
          </Grid>

          <Container sx={{ mt: 2 }}>
            <Grid xs={12} container item alignItems={"flex-start"}>
              <Grid md={12} container item rowSpacing={1}>
                <Grid container item xs={12}>
                  <Grid item xs={12} md={2} alignSelf="center">
                    <Typography variant="caption">
                      {t("summaryTab.projectId")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <Paper
                      variant="outlined"
                      sx={{
                        borderColor: "rgba(0,0,0,0.2)",
                        display: "flex",
                        alignItems: "center",
                        width: "100%"
                      }}
                    >
                      <InputBase
                        size="small"
                        disabled
                        sx={{
                          flex: 1,
                          opacity: 1
                        }}
                        value={projectData.projectId}
                      />
                      <Tooltip
                        title={t("summaryTab.copiedToClipboard")}
                        placement="top"
                        open={tooltipOpen}
                        disableHoverListener
                        disableFocusListener
                        disableTouchListener
                      >
                        <IconButton
                          color="primary"
                          onClick={handleCopy}
                          data-testid="copyToClipboardButton"
                        >
                          <CopyAll />
                        </IconButton>
                      </Tooltip>
                    </Paper>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={0.8}>
                    <ErrorOutline fontSize="small" />
                  </Grid>
                  <Grid item xs={11.2}>
                    <Typography variant="caption">
                      {t("summaryTab.projectIdInfoText")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Grid>

      {/* PROJECT PAYMENT DATA */}
      <Grid item xs={12}>
        <Box className="McpBox">
          <Grid item xs={12} justifyContent="space-between" position="relative">
            <Typography variant="h6">
              {t("summaryTab.projectPaymentHeadline")}
            </Typography>
          </Grid>

          <Container sx={{ mt: 2 }}>
            <Grid xs={12} container item alignItems={"flex-start"}>
              <Grid md={12} container item spacing={2}>
                <Grid xs={12} item>
                  <Alert status={projectData.status}>
                    <AlertTitle className="McpAlertText">
                      <Trans
                        i18nKey="projectDetails.summaryTab.paymentAlert"
                        components={{
                          Link: (
                            <a
                              className="McpAlertLink"
                              rel="noreferrer"
                              href="https://example.com"
                              target="_blank"
                            />
                          )
                        }}
                      />
                    </AlertTitle>
                  </Alert>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={0.8}>
                    <ErrorOutline fontSize="small" />
                  </Grid>
                  <Grid item xs={11.2}>
                    <Typography variant="caption">
                      {t("summaryTab.paymentMethodInfoText")}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Box className="McpBox paymentTable">
                    {paymentMethods.map((method) => (
                      <Grid item xs={12} key={method}>
                        <Box className="flexMiddlePosition">
                          <Typography variant="h6" className="noBottomBorder">
                            {method}
                          </Typography>
                          <Box>
                            <MUIChip
                              className="paymentConfigChip"
                              label={t("summaryTab.paymentChipNotConfigured")}
                              size="small"
                            />
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Grid>

      {/* ACTIVITY CONFIRMATION DIALOG */}
      <ProjectActivityConfirmationDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        newActivityStatus={isChecked ? "ACTIVE" : "INACTIVE"}
        handleActivityStatusChange={handleProjectActivity}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
    </Grid>
  )
}

export default ProjectSummaryView
