import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import translationDE from "src/shared/locales/de/translation.json"
import translationEN from "src/shared/locales/en/translation.json"

const selectedLanguage = localStorage.getItem("selectedLanguage") || "de"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: selectedLanguage,
    load: "languageOnly",
    keySeparator: ".",
    resources: {
      en: {
        translation: translationEN
      },
      de: {
        translation: translationDE
      }
    }
  })

export default i18n
