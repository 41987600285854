import { ErrorMessage } from "@hookform/error-message"
import { Box, FormLabel, MenuItem, Select } from "@mui/material"
import { Controller, RegisterOptions, useFormContext } from "react-hook-form"

type SelectItem = {
  value: string
  text: string
}

type FormSelectGroupProps = {
  inputName: string
  isLabelRequired?: boolean
  rules?: RegisterOptions
  labelName: string
  selectItems: SelectItem[]
}

const FormSelectGroup = ({
  inputName,
  isLabelRequired = false,
  labelName,
  selectItems,
  rules
}: FormSelectGroupProps) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <>
      <Controller
        control={control}
        name={inputName}
        rules={rules}
        render={({ field: { value, onChange: onDropSelect, ref } }) => (
          <>
            <FormLabel required={isLabelRequired}>{labelName}</FormLabel>
            <Select
              sx={{ color: value === "" || !value ? "grey" : "black" }}
              ref={ref}
              displayEmpty
              renderValue={value !== "" ? undefined : () => selectItems[0].text}
              onChange={(e) => onDropSelect(e.target.value)}
              value={value || ""}
              size="small"
            >
              {selectItems.map((selectItem) => (
                <MenuItem
                  value={selectItem.value}
                  key={selectItem.value}
                  sx={{ color: selectItem.value === "" ? "grey" : "black" }}
                >
                  {selectItem.text}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      />
      <ErrorMessage
        errors={errors}
        name={inputName}
        render={({ message }) => (
          <Box component="span" data-testid="errorMessage" role="alert">
            {message}
          </Box>
        )}
      />
    </>
  )
}

export default FormSelectGroup
