import { Box } from "@mui/material"
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRowParams,
  GridValidRowModel
} from "@mui/x-data-grid"
import { useAtom, useSetAtom } from "jotai"
import { useSnackbar } from "notistack"
import { FC, useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { PaginatedDataTable } from "src/features/paginatedDataTable"
import { ApiError } from "src/shared/client"
import { Chip, LoadingContainer } from "src/shared/components"
import { handleDate } from "src/shared/functions"
import { buildProjectDetailPageUrl } from "src/shared/routing"
import {
  onboardingDataAtom,
  projectDetailsTabAtom,
  projectIdAtom,
  projectListDataAtom,
  projectListPaginationAtom
} from "src/shared/stores"

type ProjectListProps = {
  open: boolean
  handleDrawerOpen: () => void
  handleDrawerClose: () => void
}

const ProjectList: FC<ProjectListProps> = ({
  open,
  handleDrawerOpen,
  handleDrawerClose
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "projectsList"
  })
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [{ data: onboardingData }] = useAtom(onboardingDataAtom)
  const [activeProjectId, setActiveProjectId] = useAtom(projectIdAtom)
  const setTabPage = useSetAtom(projectDetailsTabAtom)
  const setPagination = useSetAtom(projectListPaginationAtom)
  const [
    {
      data: projectListData,
      error: projectListDataFetchingError,
      isFetching,
      isError: isErrorFetchingProjects,
      isPending
    }
  ] = useAtom(projectListDataAtom)

  const pagination = useMemo(
    () => projectListData?.pagination,
    [projectListData?.pagination]
  )

  const defaultPagination = {
    pageSize: 10,
    page: 0
  }

  const paginationSettings: TablePaginationSettings = useMemo(
    () => ({
      pageSizeOptions: [10, 25, 50, 100],
      paginationMode: "server"
    }),
    []
  )

  const rowClickHandler: GridEventListener<"rowClick"> = useCallback(
    (event) => {
      if (!open) {
        handleDrawerOpen()
        setActiveProjectId(event.id as string)
        const projectDetailUrl = buildProjectDetailPageUrl(event.id as string)
        navigate(projectDetailUrl)
      } else if (open && event.id === activeProjectId) {
        handleDrawerClose()
        setActiveProjectId(undefined)
        setTabPage("1")
        navigate("/projects")
      } else {
        setActiveProjectId(event.id as string)
        setTabPage("1")
        const projectDetailUrl = buildProjectDetailPageUrl(event.id as string)
        navigate(projectDetailUrl)
      }
    },
    [
      handleDrawerClose,
      handleDrawerOpen,
      navigate,
      setTabPage,
      activeProjectId,
      open,
      setActiveProjectId
    ]
  )

  //automatically sets row to active when page is loaded => no need in useEffect and observer anymore
  const rowClassNameHandler = (params: GridRowParams) =>
    params.id === activeProjectId ? "active" : ""

  const generateRowData = (projectRecords?: ProjectListRecord[]) =>
    projectRecords?.map((entry: ProjectListRecord) => ({
      id: entry.projectId,
      projectId: entry.projectId,
      projectActivityStatus: entry.activityStatus,
      projectName: entry.name,
      createdAt: handleDate(entry.createdAt),
      projectWebsite: entry.url,
      projectStatus: entry.status
    }))

  const rowData = useMemo(
    () => generateRowData(projectListData?.records),
    [projectListData?.records]
  )

  const columnDefinition: GridColDef[] = useMemo(
    () => [
      {
        field: "projectId",
        headerName: t("idColumn"),
        headerClassName: "gridHeaderStyle",
        flex: 2
      },
      {
        field: "projectActivityStatus",
        headerName: t("activityStatusColumn"),
        headerClassName: "gridHeaderStyle",
        flex: 1,
        renderCell: ({
          value
        }: GridRenderCellParams<GridValidRowModel, ProjectActivityStatus>) => (
          <Chip status={value} pathname={"projects"}></Chip>
        )
      },
      {
        field: "projectName",
        headerName: t("projectNameColumn"),
        headerClassName: "gridHeaderStyle",
        flex: 2
      },
      {
        field: "projectWebsite",
        headerName: t("projectWebsiteColumn"),
        headerClassName: "gridHeaderStyle",
        flex: 2
      },
      {
        field: "createdAt",
        headerName: t("createdAtColumn"),
        headerClassName: "gridHeaderStyle",
        flex: 1
      },
      {
        field: "projectStatus",
        headerName: t("statusColumn"),
        headerClassName: "gridHeaderStyle",
        flex: 1,
        renderCell: ({
          value
        }: GridRenderCellParams<GridValidRowModel, ProjectStatus>) => (
          <Chip status={value} pathname={"projects"}></Chip>
        )
      }
    ],
    [t]
  )

  const rowCount = useMemo(
    () => projectListData?.pagination.totalRecords,
    [projectListData?.pagination.totalRecords]
  )

  useEffect(() => {
    if (projectListDataFetchingError) {
      const enqueueErrorSnackbar = async (error: ApiError) => {
        const response: ServiceError = await error.errorResponse.json()

        enqueueSnackbar({
          variant: "detailedSnackbar",
          message: t(response.code, { keyPrefix: "errorCodes" }),
          details: response.message,
          autoHideDuration: null
        })
      }
      enqueueErrorSnackbar(projectListDataFetchingError)
    }
  }, [enqueueSnackbar, projectListDataFetchingError, t])

  if (onboardingData?.status === "APPROVED" && isPending) {
    return <LoadingContainer />
  }

  if (
    isErrorFetchingProjects ||
    (!projectListData && onboardingData?.status === "APPROVED")
  ) {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        data-testid="projectListError"
        height="100px"
      >
        {t("projectListFetchFailureAlert")}
      </Box>
    )
  }

  return (
    <PaginatedDataTable
      onboardingStatus={onboardingData?.status}
      rowData={rowData ? rowData : []}
      columnDefinition={columnDefinition}
      rowCount={rowCount ? rowCount : 0}
      pagination={pagination ? pagination : defaultPagination}
      setPagination={setPagination}
      paginationSettings={paginationSettings}
      isLoading={isFetching}
      handleRowClick={rowClickHandler}
      handleRowClassName={rowClassNameHandler}
    />
  )
}

export default ProjectList
