import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { atom } from "jotai"
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query"
import { authenticationService } from "src/shared/authentication"
import { ApiError, deleteFetch, get as getClient } from "src/shared/client"
import { userAtom } from "src/shared/stores"

const rootPath = import.meta.env.VITE_API_BASE_URL

export const onboardingDocumentDataAtom = atomWithQuery<
  OnboardingDocumentList,
  ApiError
>((get) => {
  const { data: user } = get(userAtom)

  return {
    queryKey: ["onboardingDocumentData"],
    queryFn: async () => {
      const response = await (
        await getClient({
          path: `/merchant-service/onboardings/${user?.sub}/documents`
        })
      ).json()

      return response
    },

    retry: (failureCount, apiError) => {
      if (
        failureCount >= 3 ||
        [400, 403, 404].includes(apiError.errorResponse.status)
      ) {
        return false
      }

      return true
    },
    enabled: !!user?.sub,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  }
})

export const updateOnboardingDocumentDataAtom = atomWithMutation<
  OnboardingDocument,
  File,
  AxiosError
>((get) => {
  const { data: user } = get(userAtom)

  return {
    mutationFn: async (newDocument) => {
      if (!user) {
        throw new Error("User not authenticated")
      }

      const bearerToken = `Bearer ${await authenticationService.getJwtToken()}`
      const formData = new FormData()
      formData.append("file", newDocument)

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "multipart/form-data",
          Accept: "application/json"
        }
      }

      const response = await axios.post(
        `${rootPath}/merchant-service/onboardings/${user.sub}/documents`,
        formData,
        config
      )

      return response.data
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false
  }
})

export const deleteOnboardingDocumentDataAtom = atomWithMutation<
  void,
  string,
  ApiError
>((get) => {
  const { data: user } = get(userAtom)

  return {
    mutationFn: async (documentId) => {
      if (!user) {
        throw new Error("User not authenticated")
      }

      await deleteFetch({
        path: `/merchant-service/onboardings/${user.sub}/documents/${documentId}`
      })
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false
  }
})

export const downloadOnboardingDocumentAtom = atom<
  (documentId: string) => Promise<Blob>
>((get) => async (documentId) => {
  const { data: user } = get(userAtom)

  if (!user) {
    throw new Error("User not authenticated")
  }

  const downloadData = await getClient({
    path: `/merchant-service/onboardings/${user?.sub}/documents/${documentId}`,
    headers: { Accept: "*/*" }
  })

  return downloadData.blob()
})
