import { Business, CloudUploadOutlined } from "@mui/icons-material"
import { Badge, BadgeProps, Box, styled } from "@mui/material"
import { useState } from "react"
import { theme } from "src/shared/theming"

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    width: "20px",
    height: "20px",
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: "white",
    pointerEvents: "none"
  }
}))

const MerchantInfo = () => {
  const [invisible, setInvisible] = useState(true)

  const handleBadgeVisibility = () => setInvisible((prev) => !prev)

  return (
    <Box className="McpMerchantInfo">
      <StyledBadge
        theme={theme}
        badgeContent={<CloudUploadOutlined />}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        invisible={invisible}
        data-testid="badgeElement"
      >
        <Box
          onMouseEnter={handleBadgeVisibility}
          onMouseLeave={handleBadgeVisibility}
          data-testid="logoElement"
        >
          <Business />
        </Box>
      </StyledBadge>

      <Box>Control Center</Box>
    </Box>
  )
}

export default MerchantInfo
