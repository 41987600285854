import { Box, Typography } from "@mui/material"
import { GridOverlay } from "@mui/x-data-grid"
import { useTranslation } from "react-i18next"

const OnboardingStateNoRowsOverlay = () => {
  const { t } = useTranslation("translation")

  return (
    <GridOverlay>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" color="textSecondary">
          {t("projectsList.customNoRowsOverlay")}
        </Typography>
      </Box>
    </GridOverlay>
  )
}

export default OnboardingStateNoRowsOverlay
