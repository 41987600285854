import { Box, createTheme, ThemeProvider } from "@mui/material"
import { deDE as coreDE, enUS as coreEN } from "@mui/material/locale"
import type {
  GridColDef,
  GridEventListener,
  GridPaginationModel,
  GridRowClassNameParams,
  GridValidRowModel
} from "@mui/x-data-grid"
import { DataGrid } from "@mui/x-data-grid"
import { deDE, enUS } from "@mui/x-data-grid/locales"
import { deDE as pickerDE, enUS as pickerEN } from "@mui/x-date-pickers/locales"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { theme } from "src/shared/theming"

import OnboardingStateNoRowsOverlay from "./OnboardingStateNoRowsOverlay"

type PaginatedDataTableProps = {
  onboardingStatus?: OnboardingStatus
  rowData: GridValidRowModel[]
  columnDefinition: GridColDef[]
  rowCount: number
  pagination: GridPaginationModel
  setPagination: (pagination: GridPaginationModel) => void
  paginationSettings: TablePaginationSettings
  isLoading: boolean
  handleRowClick?: GridEventListener<"rowClick">
  handleRowClassName?: (params: GridRowClassNameParams) => string
}

const PaginatedDataTable: FC<PaginatedDataTableProps> = ({
  rowData,
  columnDefinition,
  rowCount,
  pagination,
  setPagination,
  paginationSettings,
  handleRowClick,
  onboardingStatus,
  handleRowClassName
}) => {
  const { i18n } = useTranslation()

  const handleDataGridLocales = (lng: string) => {
    switch (lng) {
      default:
        return [deDE, pickerDE, coreDE]
      case "en":
        return [enUS, pickerEN, coreEN]
    }
  }

  const themeWithLocales = createTheme(
    theme,
    ...handleDataGridLocales(i18n.language)
  )

  return (
    <Box>
      <ThemeProvider theme={themeWithLocales}>
        <DataGrid
          data-testid="paginatedDataTable"
          autoHeight
          rows={rowData}
          columns={columnDefinition}
          rowCount={rowCount}
          pageSizeOptions={paginationSettings.pageSizeOptions}
          paginationModel={pagination}
          paginationMode={paginationSettings.paginationMode}
          onPaginationModelChange={setPagination}
          disableRowSelectionOnClick
          onRowClick={handleRowClick}
          getRowClassName={handleRowClassName}
          resizeThrottleMs={0}
          slots={
            onboardingStatus !== "APPROVED"
              ? { noRowsOverlay: OnboardingStateNoRowsOverlay }
              : {}
          }
        />
      </ThemeProvider>
    </Box>
  )
}
export default PaginatedDataTable
