// Import custom fonts
import "src/assets/fonts/GT-Planar-Light.ttf"
import "src/assets/fonts/GT-Planar-Medium.ttf"
import "src/assets/fonts/GT-Planar-Regular.otf"
import "src/assets/fonts/GT-Planar-Bold.ttf"
import "src/assets/css/styles.css"

import { createTheme } from "@mui/material/styles"

// adjust to MCP colors
const theme = createTheme({
  shape: {
    borderRadius: 4
  },
  spacing: 8,
  palette: {
    primary: {
      main: "#0075ed",
      contrastText: "rgba(255,255,255,1)"
    },
    secondary: {
      main: "#87cb95",
      contrastText: "rgba(255,255,255,1)"
    },
    background: {
      default: "rgba(255,255,255,1)",
      paper: "rgba(255,255,255,1)"
    },
    text: {
      primary: "rgba(0,0,0,0.8)"
    },
    error: {
      main: "#D32F2F",
      dark: "#1E4620",
      contrastText: "#EBFFF0"
    },
    warning: {
      main: "#F59F56",
      dark: "#b85d10",
      contrastText: "#fff8f2"
    },
    info: {
      main: "#014361",
      dark: "#0e3445",
      contrastText: "#fff"
    },
    success: {
      main: "#1E4620",
      dark: "#1E4620",
      contrastText: "#EBFFF0"
    },

    divider: "rgba(0,0,0,0.016)"
  },
  typography: {
    fontFamily: "GT Planar",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "4rem",
      fontWeight: 200
    },
    h2: {
      fontSize: "3rem"
    },
    h3: {
      fontSize: "2rem"
    },
    h4: {
      fontSize: "1.6rem"
    },
    h5: {
      fontSize: "1.2rem"
    },
    h6: {
      fontSize: "1.1rem"
    },
    subtitle1: {
      fontSize: "1.2rem"
    },
    subtitle2: {
      fontSize: "0.8rem"
    },
    body1: {
      fontSize: "1rem"
    },
    body2: {
      fontSize: "0.9rem"
    },
    caption: {
      fontWeight: "400",
      color: "rgba(0,0,0,0.6)",
      width: "100%"
    },
    button: {
      textTransform: "none"
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          borderRadius: 4,
          borderWidth: "1px",
          borderColor: "rgba(0,0,0,0.072)",
          borderStyle: "solid"
        }
      }
    },
    // App Bar / Nav
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#EEF4FE",
          height: "42px",
          padding: "0 1.5rem",
          "& .MuiToolbar-root": {
            height: "100%",
            minHeight: "0",
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            "& button": {
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              fontSize: "14px",
              color: "rgba(0,0,0,0.8)",
              gap: "0.5rem",
              "& svg": {
                height: "20px",
                width: "20px",
                padding: 0
              }
            }
          }
        }
      }
    },
    // Labels
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: "400",
          color: "rgba(0,0,0,0.6)",
          width: "100%",
          display: "block"
        },
        asterisk: {
          color: "#FF6E6C"
        }
      }
    },
    // Outlined Input
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: "100%"
        }
      }
    },
    // Input
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "1rem",
          borderColor: "#A3A3A3",
          padding: "0.5rem 1rem",
          "&.Mui-focused": {
            borderColor: "yellow"
          },
          "&.Mui-disabled": {
            color: "rgba(0,0,0,0.7)",
            WebkitTextFillColor: "rgba(0,0,0,0.7)",
            opacity: 1
          }
        }
      }
    },
    // Stepper
    MuiStepper: {
      styleOverrides: {
        horizontal: {
          margin: "1.5rem auto"
        }
      }
    },
    // Stepper Icon
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-completed": {
            color: "#2E7D32"
          }
        }
      }
    },
    // Form Controll
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
          display: "block"
        }
      }
    },
    // Form Control Label
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          margin: 0,
          "& .MuiTypography-root": {
            width: "auto",
            display: "inline-block"
          }
        },
        asterisk: {
          color: "#FF6E6C"
        }
      }
    },
    // Link
    MuiLink: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignContent: "center",
          alignItems: "center",
          gap: "0.5rem",
          textDecoration: "none",
          "&:hover": {
            color: "rgba(0,0,0,1)",
            textDecoration: "underline"
          }
        }
      }
    },
    // Divider
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: "1.5rem 0",
          borderColor: "rgba(0,0,0,0.1)"
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          "&.MuiAlert-standardSecondary": {
            backgroundColor: "#eeeeee",
            color: "#333"
          },
          "&.MuiAlert-standardInfo": {
            backgroundColor: "#eef4fe",
            color: "#333"
          }
        }
      }
    },
    // Drawer
    MuiDrawer: {
      styleOverrides: {
        root: {},
        paper: {
          borderColor: "rgba(0,0,0,0.072)",
          display: "flex",
          padding: "1.5rem",
          flexDirection: "column",
          gap: "1.5rem"
        }
      }
    },
    // Alert Title
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          margin: "0"
        }
      }
    }
  }
})
export default theme
