import { fetchAuthSession } from "@aws-amplify/auth"
import { atomWithQuery } from "jotai-tanstack-query"
import { ApiError, get as getClient } from "src/shared/client"
import { userAtom } from "src/shared/stores"

const MAX_RETRIES = 3

export const merchantDataAtom = atomWithQuery<MerchantRecord, ApiError>(
  (get) => {
    const { data: user } = get(userAtom)

    const getMerchantData = async (
      retryCount: number = 0
    ): Promise<MerchantRecord> => {
      try {
        const response = await (
          await getClient({
            path: `/merchant-service/merchants/${user?.merchantId}`
          })
        ).json()

        return response
      } catch (error) {
        if (error instanceof ApiError) {
          const response: ServiceError = await error.errorResponse
            .clone()
            .json()

          if (response.code === "UNAUTHORIZED" && retryCount < MAX_RETRIES) {
            await fetchAuthSession({ forceRefresh: true })

            return getMerchantData(retryCount + 1)
          } else {
            throw error
          }
        } else {
          throw error
        }
      }
    }

    return {
      queryKey: ["merchantData"],
      queryFn: async () => getMerchantData(),
      retry: (failureCount, apiError) => {
        if (
          failureCount >= 3 ||
          [400, 403, 404].includes(apiError.errorResponse.status)
        ) {
          return false
        }

        return true
      },
      enabled: !!user?.merchantId,
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  }
)
